import { useTranslation } from "next-i18next";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import Logo from "../logo/Logo";
import Link from "next/link";

const Sidebar = ({ showMobilemenu }) => {
  const { data: session } = useSession();
  const { t } = useTranslation("common");
  let router = useRouter();
  const location = router.pathname;

  return (
    <>
      <div className="sidenav-header">
        <i
          className="fas fa-times fa-2x p-3 cursor-pointer text-secondary opacity-7 position-absolute end-0 top-0 d-xl-none"
          aria-hidden="true"
          id="iconSidenav"
          onClick={showMobilemenu}
        />
        <Logo />
      </div>
      <hr className="horizontal dark mt-0" />
      <div
        className="collapse navbar-collapse w-auto h-auto"
        id="sidenav-collapse-main"
      >
        <ul className="navbar-nav">
          {/* home */}
          <li className="nav-item">
            <Link
              href="/home"
              className={
                "nav-link text-wrap " +
                (location === "/home" || location.includes("/home")
                  ? "text-bota-light-blue active nav-link py-3"
                  : "nav-link text-dark py-3")
              }
              passHref
              onClick={showMobilemenu}
            >
              <i
                className={`fas fa-gauge 
                  ${
                    location === "/home" || location.includes("/home")
                      ? "text-bota-strong-cyan-lime-green"
                      : ""
                  }`}
              ></i>
              <span className="ms-3 d-inline-block">
                {t("common:menu.home")}
              </span>
            </Link>
          </li>

          {/* packages menu */}
          <li className="nav-item">
            <Link
              href="/packages"
              className={
                "nav-link text-wrap " +
                (location === "/packages" || location.includes("/packages/")
                  ? "text-bota-light-blue active nav-link py-3"
                  : "nav-link text-dark py-3")
              }
              passHref
              onClick={showMobilemenu}
            >
              <i
                className={`fas fa-file-invoice-dollar 
                  ${
                    location === "/packages" || location.includes("/packages/")
                      ? "text-bota-strong-cyan-lime-green"
                      : ""
                  }`}
              ></i>
              <span className="ms-3 d-inline-block">
                {t("common:menu.packages")}
              </span>
            </Link>
          </li>

          {/* customizations menu */}
          <li className="nav-item">
            <Link
              href="/customizations"
              className={
                "nav-link text-wrap " +
                (location === "/customizations" ||
                location.includes("/customizations/")
                  ? "text-bota-light-blue active nav-link py-3"
                  : "nav-link text-dark py-3")
              }
              passHref
              onClick={showMobilemenu}
            >
              <i
                className={`fas fa-screwdriver-wrench 
                  ${
                    location === "/customizations" ||
                    location.includes("/customizations/")
                      ? "text-bota-strong-cyan-lime-green"
                      : ""
                  }`}
              ></i>
              <span className="ms-3 d-inline-block">
                {t("common:menu.customizations")}
              </span>
            </Link>
          </li>

          {session?.user?.role === "admin" && (
            <>
              {/* subscription and invoices */}
              <li className="nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#collapseSubscriptionsMgmt"
                  role="button"
                  aria-controls="collapseSubscriptionsMgmt"
                  className={
                    "nav-link text-wrap " +
                    (location.includes("/admin/invoices") ||
                    location.includes("/admin/subscription-plans")
                      ? "text-bota-strong-cyan-lime-green active nav-link"
                      : "nav-link text-wrap")
                  }
                  aria-expanded={
                    location.includes("/admin/invoices") ||
                    location.includes("/admin/subscription-plans")
                      ? "true"
                      : "false"
                  }
                >
                  <i className="fas fa-cash-register" />
                  <span className="ms-3 d-inline-block">
                    {t("common:menu.subscriptions")}
                  </span>
                </a>

                <div
                  className={`collapse ${
                    location.includes("/admin/invoices") ||
                    location.includes("/admin/subscription-plans")
                      ? "show"
                      : ""
                  }`}
                  id="collapseSubscriptionsMgmt"
                >
                  <ul className="nav ps-3">
                    {/* subscription plans */}
                    <li className="nav-item">
                      <Link
                        href="/admin/subscription-plans"
                        className={
                          "nav-link text-wrap " +
                          (location.includes("/admin/subscription-plans")
                            ? "text-bota-light-blue active nav-link py-3"
                            : "nav-link text-dark py-3")
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-bell 
                  ${
                    location.includes("/admin/subscription-plans")
                      ? "text-bota-strong-cyan-lime-green"
                      : ""
                  }`}
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.subscription-plans")}
                        </span>
                      </Link>
                    </li>

                    {/* customer invoices */}
                    <li className="nav-item">
                      <Link
                        href="/admin/invoices"
                        className={
                          "nav-link text-wrap " +
                          (location.includes("/admin/invoices")
                            ? "text-bota-light-blue active nav-link py-3"
                            : "nav-link text-dark py-3")
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-receipt 
                  ${
                    location.includes("/admin/invoices")
                      ? "text-bota-strong-cyan-lime-green"
                      : ""
                  }`}
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.customer-invoices")}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* user management */}
              <li className="nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#collapseUserManagement"
                  role="button"
                  aria-controls="collapseUserManagement"
                  className={
                    "nav-link text-wrap " +
                    (location.includes("/admin/roles") ||
                    location.includes("/admin/users/")
                      ? "text-bota-strong-cyan-lime-green active nav-link"
                      : "nav-link text-wrap")
                  }
                  aria-expanded={
                    location.includes("/admin/roles") ||
                    location.includes("/admin/users/")
                      ? "true"
                      : "false"
                  }
                >
                  <i className="fas fa-users-gear" />
                  <span className="ms-3 d-inline-block">
                    {t("common:menu.user-management")}
                  </span>
                </a>

                <div
                  className={`collapse ${
                    location.includes("/admin/roles") ||
                    location.includes("/admin/users/")
                      ? "show"
                      : ""
                  }`}
                  id="collapseUserManagement"
                >
                  <ul className="nav ps-3">
                    <li className="nav-item">
                      <Link
                        href="/admin/roles"
                        className={
                          "nav-link text-wrap " +
                          (location === "/admin/roles" ||
                          location.includes("/admin/roles")
                            ? "text-bota-light-blue active nav-link py-3"
                            : "nav-link text-dark py-3")
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-user-shield
                  ${
                    location === "/admin/roles" ||
                    location.includes("/admin/roles")
                      ? "text-bota-strong-cyan-lime-green"
                      : ""
                  }`}
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.roles")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/admin/users/customers"
                        className={
                          "nav-link text-wrap " +
                          (location === "/admin/users/customers" ||
                          location.includes("/admin/users/customers")
                            ? "text-bota-light-blue active nav-link py-3"
                            : "nav-link text-dark py-3")
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-people-carry-box 
                  ${
                    location === "/admin/users/customers" ||
                    location.includes("/admin/users/customers")
                      ? "text-bota-strong-cyan-lime-green"
                      : ""
                  }`}
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.customers")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/admin/users/customer-support"
                        className={
                          "nav-link text-wrap " +
                          (location === "/admin/users/customer-support" ||
                          location.includes("/admin/users/customer-support")
                            ? "text-bota-light-blue active nav-link py-3"
                            : "nav-link text-dark py-3")
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-people-pulling
                  ${
                    location === "/admin/users/customer-support" ||
                    location.includes("/admin/users/customer-support")
                      ? "text-bota-strong-cyan-lime-green"
                      : ""
                  }`}
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.customer-support")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/admin/users/partners"
                        className={
                          "nav-link text-wrap " +
                          (location === "/admin/users/partners" ||
                          location.includes("/admin/users/partners")
                            ? "text-bota-light-blue active nav-link py-3"
                            : "nav-link text-dark py-3")
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-user-shield
                  ${
                    location === "/admin/users/partners" ||
                    location.includes("/admin/users/partners")
                      ? "text-bota-strong-cyan-lime-green"
                      : ""
                  }`}
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.partners")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/admin/users/admins"
                        className={
                          "nav-link text-wrap " +
                          (location === "/admin/users/admins" ||
                          location.includes("/admin/users/admins")
                            ? "text-bota-light-blue active nav-link py-3"
                            : "nav-link text-dark py-3")
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-user-shield
                  ${
                    location === "/admin/users/admins" ||
                    location.includes("/admin/users/admins")
                      ? "text-bota-strong-cyan-lime-green"
                      : ""
                  }`}
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.admins")}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </>
          )}

          {session?.user?.role === "admin" && (
            <>
              {/* support tickets */}
              <li className="nav-item">
                <Link
                  href="/support/tickets"
                  className={
                    "nav-link text-wrap " +
                    (location === "/support/tickets" ||
                    location.includes("/support/tickets")
                      ? "text-bota-light-blue active nav-link py-3"
                      : "nav-link text-dark py-3")
                  }
                  passHref
                  onClick={showMobilemenu}
                >
                  <i
                    className={`fas fa-handshake-angle 
                  ${
                    location === "/support/tickets" ||
                    location.includes("/support/tickets")
                      ? "text-bota-strong-cyan-lime-green"
                      : ""
                  }`}
                  ></i>
                  <span className="ms-3 d-inline-block">
                    {t("common:menu.support-requests")}
                  </span>
                </Link>
              </li>

              {/* settings  */}
              <li className="nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#settings"
                  role="button"
                  aria-controls="settings"
                  className={
                    "nav-link text-wrap " +
                    (location.includes("/settings/general") ||
                    location.includes("/settings/system-emails")
                      ? "text-bota-strong-cyan-lime-green active nav-link"
                      : "nav-link text-wrap")
                  }
                  aria-expanded={
                    location.includes("/settings/general") ||
                    location.includes("/settings/system-emails")
                      ? "true"
                      : "false"
                  }
                >
                  <i className="fas fa-cog" />
                  <span className="ms-3 d-inline-block">
                    {t("common:menu.settings")}
                  </span>
                </a>

                <div
                  className={`collapse ${
                    location.includes("/settings/general") ||
                    location.includes("/settings/system-emails")
                      ? "show"
                      : ""
                  }`}
                  id="settings"
                >
                  <ul className="nav ps-3">
                    {/* general */}
                    <li className="nav-item">
                      <Link
                        href="/settings/general"
                        className={
                          "nav-link text-wrap " +
                          (location === "/settings/general" ||
                          location.includes("/settings/general")
                            ? "text-bota-light-blue active nav-link py-3"
                            : "nav-link text-dark py-3")
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-toolbox
                              ${
                                location === "/settings/general" ||
                                location.includes("/settings/general")
                                  ? "text-bota-strong-cyan-lime-green"
                                  : ""
                              }`}
                        ></i>
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.general")}
                        </span>
                      </Link>
                    </li>

                    {/* system emails */}
                    <li className="nav-item">
                      <Link
                        href="/settings/system-emails"
                        className={
                          "nav-link text-wrap " +
                          (location === "/settings/system-emails" ||
                          location.includes("/settings/system-emails")
                            ? "text-bota-light-blue active nav-link py-3"
                            : "nav-link text-dark py-3")
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-envelope-circle-check
                              ${
                                location === "/settings/system-emails" ||
                                location.includes("/settings/system-emails")
                                  ? "text-bota-strong-cyan-lime-green"
                                  : ""
                              }`}
                        ></i>
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.system-emails")}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </>
          )}

          {session?.user?.role === "partner" && (
            <li className="nav-item">
              <Link
                href="/company/support"
                className={
                  "nav-link text-wrap " +
                  (location === "/company/support" ||
                  location.includes("/company/support")
                    ? "text-bota-light-blue active nav-link py-3"
                    : "nav-link text-dark py-3")
                }
                passHref
                onClick={showMobilemenu}
              >
                <i
                  className={`fas fa-ticket-alt 
                  ${
                    location === "/company/support" ||
                    location.includes("/company/support")
                      ? "text-bota-strong-cyan-lime-green"
                      : ""
                  }`}
                ></i>
                <span className="ms-3 d-inline-block">
                  {t("common:menu.support-tickets")}
                </span>
              </Link>
            </li>
          )}

          {/* profile menu */}
          <li className="nav-item">
            <Link
              href="/profile"
              className={
                "nav-link text-wrap " +
                (location === "/profile" || location.includes("/profile")
                  ? "text-bota-light-blue active nav-link py-3"
                  : "nav-link text-dark py-3")
              }
              passHref
              onClick={showMobilemenu}
            >
              <i
                className={`fas fa-user-gear 
                  ${
                    location === "/profile" || location.includes("/profile")
                      ? "text-bota-strong-cyan-lime-green"
                      : ""
                  }`}
              ></i>
              <span className="ms-3 d-inline-block">
                {t("common:menu.profile")}
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
