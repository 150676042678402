import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useSession, signOut } from "next-auth/react";
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
} from "@novu/notification-center";

const TopNav = ({ showMobmenu }) => {
  const [open, setOpen] = React.useState(false);
  const { data: session } = useSession();
  const { locales, push, pathname, asPath } = useRouter();
  const { t } = useTranslation("common");

  React.useEffect(() => {
    function updateMargin() {
      const rightElement = document.getElementById("top-nav");
      const leftElement = document.getElementById("sidenav-main");

      // check if the aside element is visible in the viewport
      const leftRect = leftElement?.getBoundingClientRect();

      if (leftRect && leftRect.right > 0 && leftRect.left < window.innerWidth) {
        const leftWidth = leftElement.clientWidth;
        rightElement.style.marginLeft = leftWidth + "px";
      } else {
        rightElement && (rightElement.style.marginLeft = "0px");
      }
    }

    updateMargin();
    window.addEventListener("resize", updateMargin);
    window.addEventListener("scroll", updateMargin);
  }, []);

  const toggleSideBar = async () => {
    if (open) {
      document.getElementById("main-content").style.marginLeft = "250px";
      document.getElementById("sidenav-main").style.display = "block";
      document.getElementById("top-nav").style.marginLeft = "250px";
      setOpen(false);
    } else {
      document.getElementById("main-content").style.marginLeft = "0px";
      document.getElementById("sidenav-main").style.display = "none";
      document.getElementById("top-nav").style.marginLeft = "0px";
      setOpen(true);
    }
  };

  return (
    <nav
      className="navbar navbar-top navbar navbar-main navbar-expand-lg sticky-top px-0 opacity-9 shadow-lg bg-white"
      data-scroll="true"
      style={{ zIndex: 99, marginLeft: "0px !important" }}
      id="top-nav"
    >
      <div className="container-fluid my-2">
        <div className="d-flex align-items-center ms-3">
          <div className="sidenav-toggler sidenav-toggler-inner cursor-pointer d-block d-xl-none">
            <a onClick={showMobmenu} className="nav-link text-body p-0">
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line fa-2x" />
                <i className="sidenav-toggler-line fa-2x" />
                <i className="sidenav-toggler-line fa-2x" />
              </div>
            </a>
          </div>
          <div className="sidenav-toggler sidenav-toggler-inner cursor-pointer d-none d-xl-block">
            <a
              onClick={() => toggleSideBar()}
              className="nav-link text-body p-0"
            >
              <div className="sidenav-toggler-inner">
                <i
                  className="sidenav-toggler-line fa-2x"
                  style={
                    !open ? { transform: "translateX(5px)", width: "13px" } : {}
                  }
                />
                <i className="sidenav-toggler-line fa-2x" />
                <i
                  className="sidenav-toggler-line fa-2x"
                  style={
                    !open ? { transform: "translateX(5px)", width: "13px" } : {}
                  }
                />
              </div>
            </a>
          </div>

          <div className="d-block d-md-none ms-5">
            <p className="text-dark">{t("common:app-name")}</p>
          </div>
        </div>

        <ul className="nav">
          <li className="dropdown me-3">
            <i
              className=" fas fa-language fa-2x text-bota-strong-cyan-lime-green"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            />
            <ul className="dropdown-menu dropdown-menu-end">
              {locales.map((locale) => (
                <li
                  key={locale}
                  onClick={() => {
                    push(pathname, asPath, { locale });
                    localStorage.setItem("BOTATENDE_LOCALE", locale);
                  }}
                >
                  <a className="dropdown-item" href="#">
                    <span>
                      <i className="fas fa-globe" />
                      <span className="ms-3">
                        {locale === "en"
                          ? "English"
                          : locale === "pt"
                          ? "Português"
                          : locale}
                      </span>
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </li>

          <li className="mx-2">
            <NovuProvider
              backendUrl={process.env.NEXT_PUBLIC_NOVU_BACKEND_URL}
              socketUrl={process.env.NEXT_PUBLIC_NOVU_SOCKET_URL}
              applicationIdentifier={process.env.NEXT_PUBLIC_NOVU_APP_ID}
              subscriberId={session?.user?.subscriberId}
              subscriberHash={session?.user?.subscriberHash}
              styles={{
                footer: {
                  root: { display: "none" },
                },
              }}
            >
              <PopoverNotificationCenter colorScheme={"light"}>
                {({ unseenCount }) => (
                  <NotificationBell unseenCount={unseenCount} />
                )}
              </PopoverNotificationCenter>
            </NovuProvider>
          </li>

          <li className="dropdown">
            <span
              className="avatar avatar-sm rounded-circle bg-bota-strong-cyan-lime-green"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fas fa-user fa-2x text-bota-light-grayish-orange" />
            </span>
            <ul className="dropdown-menu menu-2 dropdown-menu-end">
              <>
                {/* profile */}
                <li>
                  <Link href="/profile" className="dropdown-item" passHref>
                    <i className="fas fa-user" />
                    <span className="ms-3">
                      {t("common:top-nav.my-profile")}
                    </span>
                  </Link>
                </li>
                {/* billing */}
                <li>
                  <Link href="/billing" className="dropdown-item" passHref>
                    <i className="fas fa-file-invoice-dollar" />
                    <span className="ms-3">{t("common:menu.billing")}</span>
                  </Link>
                </li>
                <hr className="dropdown-divider" />
                {session && (
                  <>
                    <hr className="dropdown-divider" />

                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => signOut({ redirect: false })}
                      >
                        <i className="fas fa-person-running" />
                        <span className="ms-3">
                          {t("common:top-nav.logout")}
                        </span>
                      </a>
                    </li>
                  </>
                )}
              </>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default TopNav;
